import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useRouteMatch,
  useLocation,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import { DateTime } from 'luxon';
import { StringParam, useQueryParam } from 'use-query-params';

import { getMostRecentActiveSummative } from 'utils/getMostRecentActiveSummative';
import ProtectedRoute from 'utils/ProtectedRoute';
import useLocalStorage from 'hooks/useLocalStorage';
import { useAppDispatch } from 'store';
import activeSlice from 'store/slices/active';
import getInitialData from 'store/actions/getInitialData';
import reloadMissingQuestionsForCourse from 'store/actions/reloadMissingQuestionsForCourse';
import retrieveAssessmentsWithEnrollment from 'store/selectors/retrieveAssessmentsWithEnrollment';
import retrieveCourseAccessData from 'store/selectors/retrieveCourseAccessData';
import updateUserEnrollmentOnFirstAccess from 'store/actions/updateUserEnrollmentOnFirstAccess';
import getStudentStudyPathData from 'store/actions/getStudentStudyPathData';

import AssessmentTakerController from './AssessmentTakerController/AssessmentTakerController';
import AssignmentController from './AssignmentListController/AssignmentListController';
import AccessController from 'shared-components/AccessController/AccessController';
import CourseHomeController from './CourseHomeController';
import CourseStudyPathController from './StudyPathController/StudyPathController';
import CourseHeaderNav from 'shared-components/CourseHeaderNav/CourseHeaderNav';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import { AccessStatusEnum } from 'types/common.types';
import { Store } from 'types/store.types';
import { StudentCoursePath } from 'types/student.types';
import { YesNo } from 'types/backend/shared.types';
import './Course.scss';

export default function Course() {
  const { path, params }: { path: string; params: { id: string }} = useRouteMatch();
  const { id } = params;
  const courseId = params.id;
  const [summativeAssessmentIdFromQuery] = useQueryParam('summativeAssessmentId', StringParam);
  const [graceWarningDismissed] = useLocalStorage('GRACE_WARNING_DISMISSED', null);
  const [courseLoaded, setCourseLoaded] = useState(false);
  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();
  const passiveInstructorCourses = useSelector((store: Store) => store.passive.instructorCourses);
  const passiveStudentCourses = useSelector((store: Store) => store.passive.studentCourses);
  const course = useSelector((store: Store) => store.active.course);
  const instructorStudentViewCourseId = useSelector((store: Store) => store.state.instructorStudentViewCourseId);
  const courseAccessData = useSelector(retrieveCourseAccessData);
  const enrichedAssessmentsForStudent = useSelector(retrieveAssessmentsWithEnrollment);
  const summativeAssessmentSupplements = useSelector((store: Store) => store.active.summativeAssessmentSupplements);
  const enrollment = useSelector((store: Store) => store.active.enrollment);
  const studentAssessmentQuestions = useSelector((store: Store) => store.active.studentAssessmentQuestions);
  const studentAssessments = useSelector((store: Store) => store.active.studentAssessments);
  const assessmentQuestionMaps = useSelector((store: Store) => store.active.assessmentQuestionMaps);

  const launchingAsStudent = !instructorStudentViewCourseId;
  // if grace warning has been dismissed within the last 24 hrs, don't show again
  const msSinceGraceWarningDismissed = +DateTime.now() - parseInt(graceWarningDismissed || '0');
  const hoursTilShowWarningAgain = 24;
  const graceWarningDismissedRecently = !!graceWarningDismissed && msSinceGraceWarningDismissed < 60 * 60 * 1000 * hoursTilShowWarningAgain;
  const graceWarningUndismissed = courseAccessData?.accessStatus === AccessStatusEnum.GracePeriodWarning && !graceWarningDismissedRecently;
  const courseAccessBlocked = !instructorStudentViewCourseId && (!!courseAccessData?.blockAccess || graceWarningUndismissed);

  // courseId param should ALWAYS exist if user is in this component
  const courseFromParam = passiveInstructorCourses.concat(passiveStudentCourses).find((pc) => pc.id === courseId);
  if (!courseFromParam) {
    console.debug(`courseId ${courseId} not found in passive instructorCourses or studentCourses`, passiveInstructorCourses, passiveStudentCourses);
  }

  /**
   * Course Specific Data Fetching Effect
   *
   * When our course id from the url route changes, we
   * generate/fetch all the 'active' data for that course
   * and dispatch it to the store.
   *
   * this needs to re-run whenever any passive data changes
   *
   * JVA Sept 18, 2021, I removed user, courses, units, & topics from the dependency list
   * They were causing multiple full reloads of getInitialData in most cases
   * and for pilot (and maybe forever) they will never change without the Course component reloading anyway
   *
   * JP Sept 29, 2022. Added passive.courses back in to dependency list to fix LTI launching, getInitialData needs to fire when passive data is loaded
   */
  useEffect(() => {
    if (!!courseFromParam && !courseLoaded) {
      console.debug(`getInitialData ${courseFromParam.id}`);
      dispatch(getInitialData(courseFromParam.id, launchingAsStudent));
      setCourseLoaded(true);
    }
  }, [courseLoaded, courseFromParam, dispatch, launchingAsStudent]);

  // it might be possible to integrate this useEffect with the above but it's working as-is
  useEffect(() => {
    if (!!courseFromParam?.id && !!course?.id && courseFromParam.id !== course.id) {
      console.debug(`subsequent getInitialData ${course.id} >> ${courseFromParam.id}`);
      dispatch(getInitialData(courseFromParam.id, launchingAsStudent));
    }
  }, [courseFromParam, course, dispatch, launchingAsStudent]);

  /********************
   * This useEffect is for updating firstAccessedAt in the enrollments table
   * it updates the enrollment.firstAccessedAt if it is not null but the access (e.g. course) has started
   */
  useEffect(() => {
    const updateEnrollmentFirstAccessedAt = async (enrollmentId: number) => {
      return dispatch(updateUserEnrollmentOnFirstAccess(enrollmentId));
    };
    if (courseAccessData && courseAccessData.accessStatus !== AccessStatusEnum.NotStarted && !enrollment.firstAccessedAt) {
      updateEnrollmentFirstAccessedAt(enrollment.id).catch(console.error);
    }
  }, [courseAccessData, dispatch, enrollment]);

  /*
   * This effect loads the studyPath on first load
   * or any time the studentAssessments or studentAssessmentQuestions change
   * Such as from within any of the assessment takers
   */
  useEffect(() => {
    const activeSummative = getMostRecentActiveSummative(enrichedAssessmentsForStudent, summativeAssessmentSupplements);
    // IMPORTANT: Only override student study path with latest summative if no summative id in query to avoid a race condition
    if (!!id && !!enrollment?.id && !!activeSummative && !summativeAssessmentIdFromQuery) {
      (async () => dispatch(getStudentStudyPathData(activeSummative.id, enrollment.id)))();
    }
  }, [id, enrollment, dispatch, enrichedAssessmentsForStudent, summativeAssessmentSupplements, studentAssessments, studentAssessmentQuestions, summativeAssessmentIdFromQuery]);

  // This useEffect reloads all the questions for a student
  // whenever assessmentsQuestions is updated in state if a question is missing
  // which can happen if an instructor added a prep question to a Study Path after the student already loaded getInitialData
  useEffect(() => {
    if (courseFromParam) {
      dispatch(reloadMissingQuestionsForCourse(courseFromParam.id));
    }
  }, [assessmentQuestionMaps, courseFromParam, dispatch]);

  // clear our store.active state on course unload
  useEffect(() => {
    return () => {
      console.debug('Clear active slice');
      dispatch(activeSlice.actions.clear());
    };
  }, [dispatch]);

  // redirect link for trying to load a known route without course access
  const unauthedRedirectPath = `/student/course/${id}/${StudentCoursePath.CourseAccess}?reqPath=${encodeURIComponent(`${pathname}${search}`)}`;
  // handle unknown routes, if blocked, redirect to Course Access, if not go home
  const defaultRedirectPath = courseAccessBlocked ? `${path}/${StudentCoursePath.CourseAccess}` : `${path}/${StudentCoursePath.Home}`;
  return (
    <div className="course-page__student">
      <CourseHeaderNav
        leftTabs={[
          {
            disabled: courseAccessBlocked,
            id: 'student-nav-item__course-home',
            coursePath: StudentCoursePath.Home,
            label: 'Course Home',
          },
          {
            disabled: courseAccessBlocked,
            id: 'student-nav-item__study-path',
            coursePath: StudentCoursePath.StudyPath,
            label: 'Study Path',
          },
          {
            disabled: courseAccessBlocked,
            id: 'student-nav-item__assignments',
            coursePath: StudentCoursePath.Assignments,
            label: 'Assignments',
          },
        ]}
        rightTabs={[
          {
            coursePath: `/student/poll/${courseId}`,
            id: 'student-nav-item__tadpoll',
            label: 'Poll',
            noPrefix: true,
            show: course.enableTadpoll === YesNo.Yes,
          },
          {
            id: 'student-nav-item__course-access',
            coursePath: StudentCoursePath.CourseAccess,
            label: 'Course Access',
          },
        ]}
        linkPrefix={`/student/course/${id}/`}
        title={courseFromParam?.name}
      />
      {!!courseFromParam && courseAccessData ? (
        <Switch>
          <ProtectedRoute
            component={CourseHomeController}
            exact
            isAuthorized={!courseAccessBlocked}
            path={`${path}/${StudentCoursePath.Home}`}
            redirectPath={unauthedRedirectPath}
          />
          <ProtectedRoute
            component={CourseStudyPathController}
            exact
            isAuthorized={!courseAccessBlocked}
            path={`${path}/${StudentCoursePath.StudyPath}`}
            redirectPath={unauthedRedirectPath}
          />
          <ProtectedRoute
            component={AssessmentTakerController}
            exact
            isAuthorized={!courseAccessBlocked}
            path={`${path}/${StudentCoursePath.AssessmentTaker}/:assessmentId`}
            redirectPath={unauthedRedirectPath}
          />
          <ProtectedRoute
            component={AssignmentController}
            exact
            isAuthorized={!courseAccessBlocked}
            path={`${path}/${StudentCoursePath.Assignments}`}
            redirectPath={unauthedRedirectPath}
          />
          <Route
            component={AccessController}
            exact
            path={`${path}/${StudentCoursePath.CourseAccess}`}
          />
          <Redirect to={defaultRedirectPath} />
        </Switch>
      )
        :
        <LoadingSpinner loadingMessage="Your course is loading"/>
      }
    </div>
  );
}
